import BasicLayout from '../../Layouts/BasicLayout/BasicLayout'
import Footer from '../../Layouts/BasicLayout/Footer/Footer'
import { Helmet } from "react-helmet";
import chargegrid from '../../Assets/Home/banner-1.png'
import appstore from '../../Assets/Home/appStoreIcon.svg'
import playstore from '../../Assets/Home/playStoreIcon.svg'
import cagr from '../../Assets/Home/cagrgraph1.svg'
import cagrgraph from '../../Assets/Home/cagr3.svg'
import styles from './Home.module.scss'
import GrowInvestment from './Components/GrowInvestment';
import ContactUs from './Components/ContactUs';
import Navigate from './Components/Navigate';
import WhyZizi from './Components/WhyZizi';
import MainFeatures from './Components/MainFeatures';
import Products from './Components/Products';
import TestimonialSlider from './Components/TestimonialSlider';


const Home = () => {
    return (
        <>
            <BasicLayout>
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="shreyas" content="Place the meta description text here." />
                    <title>ZIZI</title>
                    <link rel="canonical" href="https://ziziev.com/" />
                </Helmet>
                <div style={{ paddingRight: '0.75rem', paddingLeft: '0.75rem' }}>
                    <section id="home_section">
                        <div className={`row h100 justify-content-center align-items-center ${styles.chargeContainer}`}>
                            <div className={`col-sm-6 col-md-6 `}>
                                <div className={styles.leftSection}>
                                    <h1 className={`${styles.headingText} mb-4`}>accelerating new age <br />fuel revolution </h1>
                                    <div className={styles.allCharges}>
                                        <span
                                            className={styles.charge}
                                            data-hover="EV charging Solution"
                                        >                      CHARGE
                                        </span>                    <span className={styles.gaping}>|</span>                    <span
                                            className={styles.pack}
                                            data-hover="Li-Ion Battery Solution"
                                        >                      PACK
                                        </span>                    <span className={styles.gaping}>|</span>                    <span
                                            className={styles.grid}
                                            data-hover="Ops Management Software"
                                        >                      GRID
                                        </span>                    <span className={styles.gaping}>|</span>                    <span
                                            className={styles.green}
                                            data-hover="Green Energy integration"
                                        >                      GREEN
                                        </span>
                                    </div>
                                    <div className={`${styles.imageAppStore} mt-3`}>
                                        <a href="https://apps.apple.com/us/app/zizi-ev/id6445814482" target="_blank">
                                            <img src={appstore} alt="appstore icon" width="49px" height="49px" className={styles.appStoreIcon} />
                                        </a>
                                        <a href="https://play.google.com/store/apps/details?id=com.statiq.nle.zizi" target="_blank">
                                            <img src={playstore} alt="playstore icon" width="49px" height="49px" className={styles.playStoreIcon} />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className={`col-sm-6 col-md-6 ${styles.rightSectionCharge}`}>
                                <img src={chargegrid} className={` ${styles.rightSectionImage}`} alt="grid logo" />
                            </div>
                        </div>
                    </section>
                    <section>

                        <div className={`row h100 justify-content-center align-items-center ${styles.cagrContainer}`}>
                            <div className={`col-sm-6 col-md-6`}>
                                <h2 className={styles.cagrHeading}>E.V.’s TO GROW AT A <br />
                                    C.A.G.R. OF 49%</h2>
                                <p className={styles.cagrText}>A recent KPMG study expects the EV count in India to grow to 5 crore by 2030 with annual sales breaching north of 1 crore. India has seen a 3 fold rise in the sales over the last fiscal year. <br />
                                    Under the new plan of the Indian government, every car being sold in India from 2030 will be electric</p>
                            </div>
                            <div className={`col-sm-6 col-md-6`}>
                                <img src={cagr} className={`img-responsive ${styles.cagrImage}`} />
                            </div>
                        </div>
                    </section>

                    <section>

                        <div className={`row h100 justify-content-center align-items-center ${styles.cagrGraphContainer}`}>
                            <div className={`col-sm-6 col-md-6`}>
                                <img src={cagrgraph} className={`img-responsive ${styles.cagrGraph}`} />
                            </div>
                            <div className={`col-sm-6 col-md-6`}>
                                <h2 className={styles.cagrgraphHeading}>INDIA REQUIRES 20.5 LAKH <br /> CHARGING STATIONS BY 2030</h2>
                                <p className={styles.cagrgraphText}>As per the Ministry of Power guidelines, there shall be at least one charging station at every 25 kilometres on both sides of the highway and for cities, at least one charging station will be set up in a grid of 3 kilometres by 3 kilometres.</p>
                            </div>
                        </div>
                    </section>

                    <section>
                        <WhyZizi />
                    </section>
                    <section id="product_section">
                        <Products />
                    </section>
                    <section>
                        <MainFeatures />
                    </section>


                    <section id="navigation_section">
                        <Navigate />
                    </section>
                    <section>
                        <GrowInvestment />
                    </section>

                    {/* added testimonialSlider */}
                    {/* <section>
                        <TestimonialSlider/>
                    </section> */}
                    <section id="contact_section">
                        <ContactUs />
                    </section>
                </div>
                <Footer />
            </BasicLayout>
        </>
    )
}
export default Home;