import React, { useEffect, useState } from "react";
import whyZiziStyle from "../Home.module.scss";
import wheels from "../../../Assets/Home/wheels.svg";
import labour from "../../../Assets/Home/labour.svg";
import house from "../../../Assets/Home/house.svg";
import mobileWheel from "../../../Assets/Home/mobileWheel.svg";


const WhyZizi = () => {
  // for mobile screen check
  const [isMobile, setIsMobile] = useState(false);

  // for hover check begins
  const [isHoveredFirst, setIsHoveredFirst] = useState(false);
  const [isHoveredSecond, setIsHoveredSecond] = useState(false);
  const [isHoveredThird, setIsHoveredThird] = useState(false);
  const [isHoveredFourth, setIsHoveredFourth] = useState(false);

  // hover handlers
  const handleEnterFirst = () => {
    setIsHoveredFirst(true);
  };
  const handleLeaveFirst = () => {
    setIsHoveredFirst(false);
  };

  const handleEnterSecond = () => {
    setIsHoveredSecond(true);
  };
  const handleLeaveSecond = () => {
    setIsHoveredSecond(false);
  };
  const handleEnterThird = () => {
    setIsHoveredThird(true);
  };
  const handleLeaveThird = () => {
    setIsHoveredThird(false);
  };
  const handleEnterFourth = () => {
    setIsHoveredFourth(true);
  };
  const handleLeaveFourth = () => {
    setIsHoveredFourth(false);
  };

  // for hover check ends
  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 567px)");

    // defining callbacks
    function handleMediaQueryChange(e) {
      setIsMobile(e.matches);
    }

    mediaQuery.addEventListener("change", handleMediaQueryChange);

    // checking size initially
    setIsMobile(mediaQuery.matches);

    // Clean up by removing eventListener
    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, []);

  // zizi code for mobile device
  if (isMobile) {
    return (
      <div className={`container-fluid h100 ${whyZiziStyle.ziziContainer}`}>
        {/* for text */}
        <div className={`container ${whyZiziStyle.zizitextContainer} my-5`}>
          <h3>100% VERTICALLY INTEGRATED EV CHARGING INFRA COMPANY</h3>
        </div>

        {/* for the column */}

        <div className="container-fluid">
          <div className="row">
            <div
              className={`col-xs-12 col-sm-6 col-md-6 col-lg-3 ${whyZiziStyle.ziziColumns} mb-3`}
            >
              <div
                className={`container-fluid ${whyZiziStyle.zizicardContent} my-5`}
              >
                <img src={wheels} alt="wheels logo" className="mb-3" />

                {/* implementing accordion */}
                <div className="accordion">
                  <div className="accordion-item">
                    <p className="accordion-header " id="headingOne">
                      <button
                        className={`accordion-button collapsed ${whyZiziStyle.buttonText}`}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                      >
                        STATE OF THE ART – ARAI CERTIFIED - MANUFACTURING
                      </button>
                    </p>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                        COMPLETE CONTROL OVER QUALITY
                        </p>
                        <p>ADVANCED CHARGERS UPTO 240kW AVAILABLE</p>

                        <p>
                        FLEXIBILITY FOR CUSTOMISED SOLUTION
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`col-xs-12 col-sm-6 col-md-6 col-lg-3 ${whyZiziStyle.ziziColumns} mb-3`}
            >
              <div
                className={`container-fluid ${whyZiziStyle.zizicardContent} my-5`}
              >
                <img
                  src={mobileWheel}
                  alt="mobileWheel logo"
                  className="mb-3"
                />

                {/*implementing accordion  */}
                <div className="accordion">
                  <div className="accordion-item">
                    <p className="accordion-header" id="headingTwo">
                      <button
                        className={`accordion-button collapsed ${whyZiziStyle.buttonText}`}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        OPERATIONS MANAGEMENT SOFTWARE                        </button>
                    </p>
                    <div
                      id="collapseTwo"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                        SEAMLESS INTEGRATION WITH CHARGERS AND VEHICLE
                        </p>
                        <p>
                        CUSTOM DASHBOARD FOR FLEET OPERATORS
                        </p>
                        <p>
                        AUTOMATED UNMANNED OPERATIONS AND PAYMENT INTEGRATION
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* accordion ends */}
              </div>
            </div>
            <div
              className={`col-12 col-sm-6 col-md-6 col-lg-3 ${whyZiziStyle.ziziColumns} mb-3`}
            >
              <div
                className={`container-fluid ${whyZiziStyle.zizicardContent} my-5`}
              >
                <img src={labour} alt="labour logo" className="mb-3" />

                {/*implementing accordion  */}
                <div className="accordion">
                  <div className="accordion-item">
                    <p className="accordion-header" id="headingThree">
                      <button
                        className={`accordion-button collapsed ${whyZiziStyle.buttonText}`}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        {/* <img src={labour} alt="labour logo" className="mb-3" /> */}
                        ON GROUND INSTALLATION AND SERVICE TEAM
                      </button>
                    </p>
                    <div
                      id="collapseThree"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                        EXPERIENCED TEAM WITH PAN INDIA SERVICE CAPABILITY
                        </p>
                        <p>
                        FLEXIBILITY FOR CUSTOMISED SOLUTIONS
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* accordion ends */}
              </div>
            </div>
            <div
              className={`col-12 col-sm-6 col-md-6 col-lg-3 ${whyZiziStyle.ziziColumns} mb-3`}
            >
              <div
                className={`container-fluid ${whyZiziStyle.zizicardContent} my-5`}
              >
                <img src={house} alt="house logo" className="mb-3" />

                {/*implementing accordion  */}
                <div className="accordion">
                  <div className="accordion-item">
                    <p className="accordion-header" id="headingFour">
                      <button
                        className={`accordion-button collapsed ${whyZiziStyle.buttonText}`}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                        GREEN ENERGY INTEGRATION
                      </button>
                    </p>
                    <div
                      id="collapseFour"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingFour"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                        EXPERT TEAM FOR SOLAR PLANT INTEGRATION
                        </p>
                        <p>
                        GREEN MOBILITY LICENSING FOR SEAMLESS SWITCH TO GREEN ENERGY
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* accordion ends */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <div className={`container-fluid ${whyZiziStyle.ziziContainer}`}>
          {/* for text */}
          <div className={`container ${whyZiziStyle.zizitextContainer} my-5`}>
            <h3>ZIZI – 100% VERTICALLY INTEGRATED EV CHARGING INFRA CO.</h3>
          </div>

          {/* for the column */}

          <div className="container-fluid mb-5 ">
            <div className="row">
              <div
                className={`col-12 col-sm-6 col-md-6 col-lg-3 ${isHoveredFirst ? whyZiziStyle.hoveredColumn : whyZiziStyle.ziziColumns} mb-3`}
                onMouseEnter={handleEnterFirst}
                onMouseLeave={handleLeaveFirst}
              >
                {isHoveredFirst ? (
                  <>
                    <div
                      className={`d-flex align-items-center ${whyZiziStyle.hoveredColumn}`}
                    >
                      <ul>
                        <li>
                          <p>

                            COMPLETE CONTROL OVER QUALITY
                          </p>
                        </li>
                        <li>

                          <p>ADVANCED CHARGERS UPTO 240kW AVAILABLE</p>
                        </li>
                        <li>

                          <p>
                            FLEXIBILITY FOR CUSTOMISED SOLUTION
                          </p>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className={`${whyZiziStyle.defaultItems}`}
                    >
                      <img src={wheels} alt="wheels logo" className="mb-3" />
                      <p>STATE OF THE ART – ARAI CERTIFIED - MANUFACTURING</p>
                    </div>
                  </>
                )}
              </div>
              <div
                className={`col-12 col-sm-6 col-md-6 col-lg-3 ${isHoveredSecond ? whyZiziStyle.hoveredColumn : whyZiziStyle.ziziColumns} mb-3`}
                onMouseEnter={handleEnterSecond}
                onMouseLeave={handleLeaveSecond}
              >
                {isHoveredSecond ? (
                  <>
                    <div
                      className={`d-flex align-items-center ${whyZiziStyle.hoveredColumn}`}
                    >
                      <ul>
                        <li>
                          <p>
                            SEAMLESS INTEGRATION WITH CHARGERS AND VEHICLE
                          </p>
                        </li>
                        <li>

                          <p>CUSTOM DASHBOARD FOR FLEET OPERATORS</p>
                        </li>
                        <li>

                          <p>
                            AUTOMATED UNMANNED OPERATIONS AND PAYMENT
                            INTEGRATION
                          </p>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className={`${whyZiziStyle.defaultItems}`}
                    >
                      <img
                        src={mobileWheel}
                        alt="mobileWheel logo"
                        className="mb-3"
                      />
                      <p>OPERATIONS MANAGEMENT SOFTWARE</p>
                    </div>
                  </>
                )}
              </div>
              <div
                className={`col-12 col-sm-6 col-md-6 col-lg-3  ${isHoveredThird ? whyZiziStyle.hoveredColumn : whyZiziStyle.ziziColumns} mb-3`}
                onMouseEnter={handleEnterThird}
                onMouseLeave={handleLeaveThird}
              >
                {isHoveredThird ? (
                  <>
                    <div
                      className={`d-flex align-items-center ${whyZiziStyle.hoveredColumn}`}
                    >
                      <ul>
                        <li>
                          <p>
                            EXPERIENCED TEAM WITH PAN INDIA SERVICE CAPABILITY
                          </p>
                        </li>
                        <li>
                          <p>
                            FLEXIBILITY FOR CUSTOMISED SOLUTIONS
                          </p>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className={`${whyZiziStyle.defaultItems}`}
                    >
                      <img src={labour} alt="labour logo" className="mb-3" />
                      <p>ON GROUND INSTALLATION AND SERVICE TEAM</p>
                    </div>
                  </>
                )}
              </div>
              <div
                className={`col-12 col-sm-6 col-md-6 col-lg-3  ${isHoveredFourth ? whyZiziStyle.hoveredColumn : whyZiziStyle.ziziColumns} mb-3`}
                onMouseEnter={handleEnterFourth}
                onMouseLeave={handleLeaveFourth}
              >
                {isHoveredFourth ? (
                  <>
                    <div
                      className={`d-flex align-items-center ${whyZiziStyle.hoveredColumn}`}
                    >
                      <ul>
                        <li>
                          <p>

                            EXPERT TEAM FOR SOLAR PLANT INTEGRATION
                          </p>
                        </li>

                        <li>

                          <p>
                            GREEN MOBILITY LICENSING FOR SEAMLESS SWITCH TO GREEN ENERGY
                          </p>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : (
                  <>
                    <div className={`${whyZiziStyle.defaultItems}`} >
                      <img src={house} alt="house logo" className="mb-3" />
                      <p>GREEN ENERGY INTEGRATION</p>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default WhyZizi;