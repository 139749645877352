import Home from "../Pages/Home/Home";
import PrivacyPolicy from "../Pages/PrivacyPolicy";
import TermsAndConditions from "../Pages/TermsAndConditions";


export const navLinks = [
    {
        title: 'Home',
        path: "/",
        exact: true,
        element: <Home />
    },
    {
        title: 'EV Chargers',
        path: "/#product_section",
        exact: true,
        element: <Home />
    },
   {
        title: 'Li Ion Solution',
        path: "/#product_section",
        exact: true,
        element: <Home />
    },
    {
        title: 'Zizi Grid',
        path: "/#product_section",
        exact: true,
        element: <Home />
    },
    {
        title: 'Privacy Policy',
        path: "/privacy-policy",
        exact: true,
        element: <PrivacyPolicy />
    },
    {
        title: 'Terms and Conditions',
        path: "/terms",
        exact: true,
        element: <TermsAndConditions />
    }
   
]